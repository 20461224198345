<template>
  <div>
    <a-row :gutter="24" type="flex">
      <a-col :span="24" class="mb-24">
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0, }">
          <template #title>
            <a-row type="flex" align="middle">
              <a-col :span="24" class="title-wrap">
                <h5 class="font-semibold m-0">改名历史查询</h5>
                <span>本月剩余次数:{{ userInfo.historyUsernameNum }}</span>
              </a-col>
            </a-row>
          </template>

          <div class="search-wrap">
            <a-spin :spinning="spinning" style="width: 500px;">
              <a-input-search v-model="name" class="input-search" placeholder="请输入@后面的字段。比如查询@elonmusk，只需输入elonmusk" enter-button
                @search="onSearch" />
            </a-spin>
            <a-timeline class="timeline-list">
              <a-timeline-item v-for="(item, index) in list" :key="index" class="timeline-item">
                <span class="name">{{ item.username }}</span>
                <span class="time">{{ item.last_checked }}</span>
              </a-timeline-item>
            </a-timeline>
          </div>

        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>

import { searchHistoryUsername } from "@/api/service.js";
import { getUserInfo } from "@/api/user.js";

const formattedDate = (dateString, format) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，所以加1
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  if (format == 'yyyy-mm-dd h:m:s') {
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  } else if (format == 'yyyy-mm-dd') {
    return `${year}-${month}-${day}`;
  } else if (format == 'yyyy-mm-dd h') {
    return `${year}-${month}-${day} ${hours}`;
  }
}
export default ({
  components: {
  },
  data() {
    return {
      spinning: false,
      list: [],
      name: '',
      userInfo: {
        historyUsernameNum: ''
      }
    }
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      getUserInfo().then(res => {
        this.userInfo = res.data.data;
        localStorage.setItem('user_info', JSON.stringify(this.userInfo));
      })
    },
    onSearch() {
      this.spinning = true;
      this.list = [];
      searchHistoryUsername({
        name: this.name
      }).then(res => {
        this.getUserInfo();
        let record = res.data.data.record.reverse();
        record.forEach(item => {
          item.last_checked = formattedDate(item.last_checked, 'yyyy-mm-dd h:m:s');
        });
        this.list = record;
        this.spinning = false;
      }).catch(err => {
        this.spinning = false;
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.title-wrap {
  display: flex;
  align-items: center;

  span {
    font-size: 14px;
    font-weight: 400;
    color: #666;
  }
}

.search-wrap {
  padding: 0 20px;
  min-height: 600px;

  .input-search {
    width: 500px;
  }

  .timeline-list {
    padding-top: 30px;

    .name {
      font-size: 16px;
      font-weight: 600;
    }

    .time {
      padding-left: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #999;
    }
  }

}
</style>